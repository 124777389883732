<template>
  <div>

    <img src="/assets/img/HLL_logo.jpg" class="hll_logo" alt="HLL Logo">

    <div v-if="error" class="error">
      <strong>HATA:</strong> {{ errorMessage }}
    </div>

    <div v-if="!error" class="process">
      <img src="/assets/img/48x48.gif" alt="loading"><br /><br />
      Lütfen bekleyiniz..
    </div>

  </div>

</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      secretKey: "HJx7t2KxTCHYvtzB4w7AQhVRaf7bTm9WWFQpBMiR@j7Gpgw8",
      userData: {},
      userDataHLL: {},
      error: false,
      errorMessage: null,
    }
  },
  beforeMount() {
    this.getParams()
  },
  methods: {
    getParams() {
      let CryptoJS = require("crypto-js");
      const arr = this.$route.query;
      let jsonArr = {};
      const decode = decodeURIComponent(atob(arr.q));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      jsonArr = JSON.parse(bytes);
      console.log('jsonArr', jsonArr)
      this.userData = jsonArr

      // eklemeler/güncellemeler yapılıyor
      this.userData.record_method = 'hlllogin'
      this.userData.role = this.userData.roleType === 'student' ? 'student' : 'teacher'
      this.userData.password = this.userData.roleType === 'student' ? '123456' : 'Ues1234!'

      // gelen parametre kontrolleri et
      if (!this.userData.first_name || this.userData.first_name === "") {
        this.error = true
        this.errorMessage = 'first_name parametresi eksik.'
      }

      if (!this.userData.last_name || this.userData.last_name === "") {
        this.error = true
        this.errorMessage = 'last_name parametresi eksik.'
      }

      if (!this.userData.username || this.userData.username === "") {
        this.error = true
        this.errorMessage = 'username parametresi eksik.'
      }

      if (!this.userData.campus_name || this.userData.campus_name === "") {
        this.error = true
        this.errorMessage = 'campus_name parametresi eksik.'
      }

      if ((!this.userData.campus_id || this.userData.campus_id === "") && this.userData.campus_id !== 0) {
        this.error = true
        this.errorMessage = 'campus_id parametresi eksik.'
      }

      if (!this.userData.school_id || this.userData.school_id === "") {
        this.error = true
        this.errorMessage = 'school_id parametresi eksik.'
      }

      if (!this.userData.roleType || this.userData.roleType === "") {
        this.error = true
        this.errorMessage = 'roleType parametresi eksik.'
      }

      if (typeof(this.userData.school_id) !== 'number') {
        this.error = true
        this.errorMessage = 'school_id değişkeni Integer olmalıdır.'
      }

      if ('classArray' in this.userData) {
        console.log('classArray key exists!');
      } else {

        if (typeof(this.userData.class) !== 'object') {
          this.error = true
          this.errorMessage = 'class değişkeni Array olmalıdır.'
        }

        if (this.userData.class.length < 1) {
          this.error = true
          this.errorMessage = 'class değişkeni içerisinde sınıf ve şube bilgisi olmalıdır.'
        }

        var gradeCheck = this.userData.class.find(function(element) {
            return typeof element['grade'] !== 'number'
        });

        var branchCheck = this.userData.class.find(function(element) {
          return (typeof element['branch'] !== 'string') || !element['branch']
        });

      }

      if (gradeCheck) {
        this.error = true
        this.errorMessage = 'class değişkeni içerisinde grade alanı Integer olmalıdır.'
      }

      if (branchCheck) {
        this.error = true
        this.errorMessage = 'class değişkeni içerisinde branch alanı String olmalıdır.'
      }

      if (!this.error) {
        this.ueslmsCheckUser()
      }
    },

    ueslmsCheckUser() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/check2', { user: this.userData } )
        .then(response => {
          if (response.data.success) {
            this.userDataHLL = response.data.user
            this.goHLL()
          } else {
            this.ueslmsCreate()
          }
        })
        .catch(response => {
          console.log('ERR', response)
        })
    },

    ueslmsCreate() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/create', this.userData )
      .then(response => {
        this.userDataHLL = response.data.user
        this.goHLL()
      })
      .catch(response => {
        console.log('ERR', response)
      })
    },

    goHLL() {
      if (this.userDataHLL === undefined || !this.userDataHLL.company_code) {
        this.error = true
        this.errorMessage = 'Company Code LMS sisteminde tanımlı değil.'
      }

      if (!this.error) {
        // console.log('GO HLL', this.userDataHLL)
        window.go_hll(this.userDataHLL)
      }
    },
  },
}
</script>

<style scoped>
body {
  font-family: 'Arial', serif;
  width: 800px;
  margin: 0 auto;
}

.error {
  margin-top: 50px;
  background-color: #cc0000;
  padding: 10px;
  color: #ffffff;
  text-align: center;
}

.hll_logo {
  width: 150px;
  margin: 50px auto 0 auto;
  display: block;
}

.process {
  margin-top: 50px;
  text-align: center;
}

</style>
